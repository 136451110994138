import { translate } from '@/core/localization/translate';
export const {
  footerLegalCompanyInformation,
  footerLegalCompanyAddress,
  footerLegalCompanyDetailsDescription,
  footerLegalCompanyDetailsTitle,
  footerLegalConsumptionInformation,
  footerLegalConsumptionInformationB2B,
  footerLegalConsumptionInformationHref,
  footerLegalConsumptionInformationHrefB2B,
  footerLegalManageCookies,
  footerLegalMentions,
  footerLegalMentionsHref,
  footerLegalMentionsHrefB2B,
  footerLegalProtection,
  footerLegalProtectionHref,
  footerLegalReports,
  footerLegalReportsHref,
  footerLegalTerms,
  footerLegalTermsHref,
  footerLegalTermsHrefB2B,
  footerLegalTrustCenter,
  footerLegalTrustCenterHref,
  footerSitemap,
  footerSitemapHref
} = translate({
  "footerLegalCompanyInformation": "footer.legal.company.information",
  "footerLegalCompanyAddress": "footer.company.address",
  "footerLegalCompanyDetailsDescription": "footer.company.details.description",
  "footerLegalCompanyDetailsTitle": "footer.company.details.title",
  "footerLegalConsumptionInformation": "Consumer information",
  "footerLegalConsumptionInformationB2B": "Bewertungen auf der Plattform",
  "footerLegalConsumptionInformationHref": "https://cdn.manomano.com/legal/consumer-information/gb.pdf",
  "footerLegalConsumptionInformationHrefB2B": "https://cdn.manomano.com/legal/consumer-information/gb.pdf",
  "footerLegalManageCookies": "Manage cookies",
  "footerLegalMentions": "Legal Notice",
  "footerLegalMentionsHref": "https://cdn.manomano.com/legal/legal-notice/gb.pdf",
  "footerLegalMentionsHrefB2B": "https://cdn.manomano.com/legal/legal-notice/gb.pdf",
  "footerLegalProtection": "Data Protection Policy",
  "footerLegalProtectionHref": "https://cdn.manomano.com/legal/data-protection/gb.pdf",
  "footerLegalTrustCenter": "Trust Center",
  "footerLegalTrustCenterHref": "https://trust.manomano.com/",
  "footerLegalReports": "Reports",
  "footerLegalReportsHref": "/lp/rapport-de-transparence-fr-3226",
  "footerLegalTerms": "Terms and conditions",
  "footerLegalTermsHref": "https://cdn.manomano.com/legal/terms-of-service/gb.pdf",
  "footerLegalTermsHrefB2B": "https://cdn.manomano.com/legal/terms-of-service/gb.pdf",
  "footerSitemap": "Sitemap",
  "footerSitemapHref": "/sitemap"
});