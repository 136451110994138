import { translate, translateFormatter } from '@/core/localization/translate';
export const countdownDaysLeft = translateFormatter<{
  days: number;
}>("{days, plural, =1 {# day left} other {# days left}}", undefined, undefined, "en");
export const countdownTimeLeft = translateFormatter<{
  hoursFormatted: string;
  minutesFormatted: string;
  secondsFormatted: string;
}>("{hoursFormatted}hr {minutesFormatted}m {secondsFormatted}s", undefined, undefined, "en");
export const {
  countdownDays,
  countdownHours,
  countdownMinutes,
  countdownSeconds
} = translate({
  "countdownDays": "DAYS",
  "countdownHours": "HOURS",
  "countdownMinutes": "MINS",
  "countdownSeconds": "SECS"
});