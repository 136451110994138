import { translate } from '@/domains/core/localization/translate';
export const {
  defaultTopProductsTitle,
  // = mobile
  topProductsB2BTitle,
  topSalesTitle,
  lastSeenTitle,
  similarityTitle,
  favoriteProductsB2BTitle
} = translate({
  "defaultTopProductsTitle": "Top sales",
  "topProductsB2BTitle": "Top Pro products",
  "topSalesTitle": "Featured top sales",
  "lastSeenTitle": "Recently viewed products",
  "similarityTitle": "You may also like",
  "favoriteProductsB2BTitle": "Suggested products"
});