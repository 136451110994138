import { translate } from '@/domains/core/localization/translate';
export const {
  headerSearchB2C,
  headerSearchB2B,
  headerSearchCommon,
  headerSearchAI
} = {
  headerSearchB2C: translate({
    "label": "Search on ManoMano",
    "placeholder": "Look for a product or a brand ",
    "placeholderMobile": "Search on ManoMano"
  }),
  headerSearchB2B: translate({
    "label": "Search for products and brands",
    "placeholder": "Look for a product or brand",
    "placeholderMobile": "Search on ManoMano"
  }),
  headerSearchCommon: translate({
    "delete": "Clean",
    "magnifyingGlass": "Search"
  }),
  headerSearchAI: translate({
    "placeholder": "Que recherchez-vous ?"
  })
};