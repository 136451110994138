import { translate, translateFormatter, type RichTextFormatter } from '@/domains/core/localization/translate';
export const {
  sponsorBrandInfoTitle
} = translate({
  "sponsorBrandInfoTitle": "Information"
});
export const sponsorBrandInfoDescription = translateFormatter<{
  brand: string;
}>("Content sponsored by the brand {brand}", undefined, undefined, "en");
export const {
  sponsorInfoTitle
} = translate({
  "sponsorInfoTitle": "Information about a sponsored link"
});
export const sponsorInfoModalDescription = translateFormatter("Products sponsored as part of an optional paid service subscribed to by the Seller. <a>Find out more about sponsored products</a>. You're seeing this ad based on your search terms or products viewed on this page.", undefined, undefined, "en");
export const sponsorInfoDescription = translateFormatter("Product sponsored as part of an optional paid service subscribed to by the Seller. <a>Find out more about sponsored products</a>. You're seeing this ad based on your product search.", undefined, undefined, "en");
export const {
  knowMoreLink
} = translate({
  "knowMoreLink": "https://cdn.manomano.com/legal/consumer-information/gb.pdf"
});
export const advertiserName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Advertiser's name:</strong> {name}", undefined, undefined, "en");
export const advertiserPayerName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Who pays for this ad:</strong> {name}", undefined, undefined, "en");