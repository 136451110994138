import { translate } from '@/domains/core/localization/translate';
export const {
  rootLabel
} = translate({
  "rootLabel": "Search suggestions"
});
export const {
  autocomplete,
  emptySearchB2C,
  emptySearchB2B,
  autocompleteB2B,
  searchHistory,
  deleteHistory,
  searchAI
} = {
  autocomplete: translate({
    "searchBrandTitle": "Top Brands"
  }),
  emptySearchB2C: translate({
    "searchBrandTitle": "Featured brands",
    "searchCategoryTitle": "Featured categories",
    "searchQueryTitle": "Popular searches"
  }),
  emptySearchB2B: translate({
    "searchBrandTitle": "Pro brands",
    "searchCategoryTitle": "Featured categories",
    "searchQueryTitle": "Pro searches"
  }),
  autocompleteB2B: translate({
    "searchProSelection": "Top Pro selections"
  }),
  searchHistory: translate({
    "title": "My recent searches"
  }),
  deleteHistory: translate({
    "title": "Delete all"
  }),
  searchAI: translate({
    "title": "Exemples de recherches",
    "history": "Recherches r\xE9centes"
  })
};